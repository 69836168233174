import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';
import { FormFieldStructure } from 'app/forms/structure/form-field.structure';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';

export const ADD_CHILD = 'ADD_CHILD';
export const addChild = (path, child: FormGroupStructure, formKey?: string) => ({ type: ADD_CHILD, path, child, formKey });

export const REMOVE_CHILD = 'REMOVE_CHILD';
export const removeChild = (path, formKey?: string) => ({ type: REMOVE_CHILD, path, formKey });

export const UPDATE_FIELD = 'UPDATE_FIELD';
export const updateField = (path, fieldStructure, formKey?: string) => ({ type: UPDATE_FIELD, path, field: fieldStructure, formKey });

export const RESET_FORM = 'RESET_FORM';
export const resetForm = (formKey?: string) => ({ type: RESET_FORM, formKey });

export const RESET_FORM_GROUP = 'RESET_FORM_GROUP';
export const resetFormGroup = (path, formKey?: string) => ({ type: RESET_FORM_GROUP, path, formKey });

export const RESET_FIELD = 'RESET_FIELD';
export const resetField = (path, formKey?: string) => ({ type: RESET_FIELD, path, formKey });

export const APPLY_NEW_STRUCTURE = 'APPLY_NEW_STRUCTURE';
export const applyNewStructure = (structure, formKey?: string) => ({ type: APPLY_NEW_STRUCTURE, structure, formKey });

export const PATCH_GROUP_VALUES = 'PATCH_GROUP_VALUES';
export const patchGroupValues = (path, values, formKey?: string) => ({ type: PATCH_GROUP_VALUES, path, values, formKey });

export const SET_GROUP_VALUES = 'SET_GROUP_VALUES';
export const setGroupValues = (path, values, formKey?: string) => ({ type: SET_GROUP_VALUES, path, values, formKey });

export const FORCE_VALIDATION = 'FORCE_VALIDATION';
export const forceValidation = (formKey?: string) => ({ type: FORCE_VALIDATION, formKey });

export const SET_API_ERRORS = 'SET_API_ERRORS';
export const setApiErrors = (errors: ValidationErrorJSON[], formKey?: string) => ({ type: SET_API_ERRORS, errors, formKey });

export const CLEAR_API_ERRORS = 'CLEAR_API_ERRORS';
export const clearApiErrors = (formKey?: string) => ({ type: CLEAR_API_ERRORS, formKey });

export const DISABLE = 'DISABLE';
export const disable = (path, formKey?: string) => ({ type: DISABLE, path, formKey });

export const ENABLE = 'ENABLE';
export const enable = (path, formKey?: string) => ({ type: ENABLE, path, formKey });

export const updateFieldProperty = (field: NavCarrierFormControl, property: FieldProperty, propValue, formKey?: string) => {
  if (property === 'value') {
    field.value = propValue;
  }
  const path = field.getPath().join('.');
  const { dirty, touched, disabled, value } = field;

  const fieldStructure = { value, dirty, disabled, touched, [property]: propValue };

  return updateField(path, fieldStructure, formKey);
};

export const updateFieldPropertyByPath = (path: string, property: FieldProperty, propValue, formKey?: string) => {
  let fieldStructure: FormFieldStructure = {
    type: FormStructureType.Field,
  };
  if (property === 'value') {
    fieldStructure.value = propValue;
    fieldStructure.touched = true;
    fieldStructure.dirty = true;
  }
  fieldStructure = { ...fieldStructure, [property]: propValue };
  return updateField(path, fieldStructure, formKey);
};

export const setFieldValue = (field: NavCarrierFormControl, value, formKey?: string) => {
  return updateFieldProperty(field, 'value', value, formKey);
};

export type FieldProperty = 'dirty' | 'touched' | 'disabled' | 'value';
export type GroupProperty = 'disabled' | 'value';

export const getBoundFormActionsForForm = (formKey: string) => {
  return {
    addChild: (path, child) => addChild(path, child, formKey),
    removeChild: (path) => removeChild(path, formKey),
    updateField: (path, fieldStructure) => updateField(path, fieldStructure, formKey),
    resetForm: () => resetForm(formKey),
    resetFormGroup: (path) => resetFormGroup(path, formKey),
    resetField: (path) => resetField(path, formKey),
    applyNewStructure: (structure) => applyNewStructure(structure, formKey),
    patchGroupValues: (path, values) => patchGroupValues(path, values, formKey),
    setGroupValues: (path, values) => setGroupValues(path, values, formKey),
    updateFieldProperty: (field: NavCarrierFormControl, property: FieldProperty, propValue) => updateFieldProperty(field, property, propValue, formKey),
    updateFieldPropertyByPath: (path: string, property: FieldProperty, propValue) => updateFieldPropertyByPath(path, property, propValue, formKey),
    setFieldValue: (field: NavCarrierFormControl, value) => setFieldValue(field, value, formKey),
    forceValidation: () => forceValidation(formKey),
    enable: (item: CHRFormControl) => enable(item.getPath().join('.'), formKey),
    disable: (item: CHRFormControl) => disable(item.getPath().join('.'), formKey),
    setApiErrors: (errors) => setApiErrors(errors, formKey),
    clearApiErrors: () => clearApiErrors(formKey),
  };
};
